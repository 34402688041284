import {ApiResponse, http} from "@/common/http";
import {AxiosResponse} from "axios";
import {AuditLogFilter, AuditLogItem} from "@/admin/auditLog/AuditLog";

export const getAuditLog = async (filter: AuditLogFilter): Promise<ApiResponse<AuditLogItem>> => {
    const {data} = await http.post<
        AuditLogFilter,
        AxiosResponse<ApiResponse<AuditLogItem>>>(`/audit-log`, filter);

    return Promise.resolve(data);
};