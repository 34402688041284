import {ElNotification} from "element-plus";
import {i18n} from "@/common/localization";

export const showErrorMessage = (message: string, title = i18n.global.t('error')) => {
    ElNotification({
        title: title,
        message: message,
        type: 'error',
    });
}

export const showWarningMessage = (message: string, title = i18n.global.t('warning')) => {
    ElNotification({
        title: title,
        message: message,
        type: 'warning',
    });
}

export const showSuccessMessage = (message: string) => {
    ElNotification({
        message: message,
        type: 'success',
    });
}