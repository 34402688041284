export const formatMoney = (value: string | number, digits = 2) => {
  function currencyFormat(num: number) {
    return num.toFixed(digits).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
  }

  if (value) {
    return currencyFormat(Number(value));
  } else {
    return 0;
  }
}

export const formatDate = (value: Date | string) => format(value);

export const formatDateTime = (value: Date | string, showSeconds = false) => {
  if (showSeconds) {
    return format(value, {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  } else {
    return format(value, {
      hour: "2-digit",
      minute: "2-digit",
    });
  }
};

const format = (value: Date | string, params: Intl.DateTimeFormatOptions = {}) => {
  return value instanceof Date
    ? value.toLocaleDateString("ru-RU", params)
    : new Date(value).toLocaleDateString("ru-RU", params);
};
