import {ref, Ref} from "vue";
import {AuditLogFilter} from "@/admin/auditLog/AuditLog";

export const isShowAuditDialog: Ref<boolean> = ref(false);

export const showAuditLogDialog = (aggregateType: string, aggregateId: string) => {
    isShowAuditDialog.value = true;
    auditLogFilter.value.aggregateType = aggregateType;
    auditLogFilter.value.aggregateId = aggregateId;
}

export const auditLogFilter: Ref<AuditLogFilter> = ref({
    aggregateType: null,
    aggregateId: null,
})