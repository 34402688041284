import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache) {
  const _component_el_skeleton = _resolveComponent("el-skeleton");
  return _openBlock(), _createBlock(_component_el_skeleton, {
    class: "app-list-item-skeleton"
  }, {
    template: _withCtx(() => [_createVNode(_component_el_skeleton, {
      rows: 1,
      animated: "",
      style: {
        "width": "100%",
        "padding-bottom": "0"
      }
    })]),
    _: 1
  });
}